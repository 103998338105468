import React from 'react';
import { isEmpty, capitalize, chain, isArray, isObject, isString, get, cloneDeep } from 'lodash';
import genSlug from 'slugify';
import { time } from 'uniqid';
import { Icon, Tooltip } from 'antd';
import { API_URL, PLATFORM } from './constants';

/* eslint-disable */
function pairObject(value, key = null, data = {}) {
  return { text: value, value, key, data };
}

function pairObjectValue(value, key = null, data = {}) {
  return { text: value, value: key, key, data };
}

function mapFilter(value) {
  return { text: value, value };
}

function unPairObject(value) {
  return value.text;
}

function labelInValue(id, value) {
  return { key: id, value };
}

function mapId(data) {
  return data && data.map(item => (item || {})._id);
}

function getOneUploadId(upload) {
  let result;
  if (isEmpty(upload)) {
    result = undefined;
  }

  if (Array.isArray(upload)) {
    if (upload.length > 0) {
      result = upload[0].response.id;
    } else {
      result = undefined;
    }
  } else {
    result = upload;
  }

  return result;
}

function getManyUploadId(upload) {
  if (isEmpty(upload)) {
    return undefined;
  }

  if ('fileList' in upload) {
    const { fileList } = upload;
    return fileList.map(file => file.response.id);
  }

  return upload.map(file => file.response.id);
}

function mapToFileList(data) {
  if (data.some(element => isEmpty(element))) {
    return [];
  }

  return data.map(item => {
    if (!item._id && !item.id) return item[item.length - 1];
    const file = {
      id: item._id || item.id,
      uid: time(),
      name: item.name,
      url: item.url,
    };
    return { ...file, response: file };
  });
}

function convertToFileList(data) {
  if (data.some(element => isEmpty(element))) {
    return [];
  }

  return data.map(item => {
    if (!item._id && !item.id) return item;
    const file = {
      id: item._id || item.id,
      uid: time(),
      name: item.name,
      url: item.url,
    };
    return { ...file, response: file };
  });
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function slugify(string) {
  return genSlug(string, { lower: true });
}

function isIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function orderByAscending(field, data) {
  return data.sort((previous, next) => previous[field] - next[field]);
}

function isArrayOfString(data) {
  return data.some(item => typeof item === 'string');
}

function getAbilitySubject(path) {
  if (!path) return false;
  const paths = path.split('/').filter(item => !!item);
  return (paths.indexOf('reporting') > -1 || paths.indexOf('tools') > -1)
    ? paths
        .pop()
        .split('-')
        .map(item => capitalize(item))
        .join(' ')
        .replace('Vdms', 'VDMS')
        .replace('Mrss', 'MRSS')
        .replace('Csv', 'CSV')
    : paths
        .shift()
        .split('-')
        .map(item => capitalize(item))
        .join(' ')
        .replace('Vdms', 'VDMS')
        .replace('Mrss', 'MRSS')
        .replace('Csv', 'CSV');
}

function getAbilityAction(path) {
  if (!path) return false;
  let action = 'view';
  if (path.indexOf('add') > -1) {
    action = 'add';
  } else if (path.indexOf('edit') > -1) {
    action = 'edit';
  }
  if (path.indexOf('manually-add') > -1) {
    action = 'view';
  }
  return action;
}

function convertFieldToAction(fields) {
  const items = [];
  const mustHaveActions = ['view', 'add', 'edit', 'delete'];
  Object.entries(fields).forEach(([key, value]) => {
    if (key.indexOf('action') > -1 && value.length > 0) {
      const notHaveActions = difference(mustHaveActions, value);
      const data = {};
      const name = key.replace('action_', '').split('_');
      if (name.length > 1) {
        data._id = name.pop();
        data.subject = name.pop();
      } else {
        data.subject = name.pop();
      }
      data.resource = `/${data.subject.toLowerCase().replace(/ /g, '_')}`;
      for (const action of value) {
        data[action] = true;
      }
      for (const action of notHaveActions) {
        data[action] = false;
      }
      items.push(data);
    }
  });
  return items;
}

function convertActionToField(items) {
  const fields = [];
  if (!items) {
    return fields;
  }
  Object.entries(items).forEach(([key, value]) => {
    if (typeof value === 'boolean') {
      if (value) {
        fields.push(key);
      }
    }
  });
  return fields;
}

function difference(array, values) {
  array = array || [];
  values = values || [];
  return array.filter(item => !values.includes(item));
}

function toHyphenCase(name) {
  return (name || '').toLowerCase().split(' ').join('-');
}

function transformFilters(key, data) {
  return chain(data)
    .map(item => {
      const root = key.indexOf('.') > -1 ? key.split('.').shift() : key;
      console.log(key.split('.').unshift());
      if (isString(item[root]) || isObject(item[root])) {
        return get(item, key);
      }
      if (isArray(item[root])) {
        return item[root].map(element => {
          const lastKey = key.split('.').pop();
          return element[lastKey];
        });
      }
    })
    .flattenDeep()
    .uniq()
    .filter(item => !!item)
    .map(item => ({ text: item, value: item }))
    .value();
}

function transformRightsHolder(data) {
  return chain(data)
    .filter(item => !!item)
    .map(item => item.company)
    .filter(item => !!item)
    .filter((item, index, self) => self.indexOf(item) === index)
    .orderBy([], ['asc'])
    .uniq()
    .map(item => pairObject(item))
    .value();
}

function transformRightsHolderWithKey(data) {
  return chain(data)
    .filter(item => !!item)
    .map(item => ({ value: item.company, key: item._id }))
    .filter(item => !!item)
    .filter((item, index, self) => self.indexOf(item) === index)
    .orderBy([], ['asc'])
    .uniq()
    .map(item => pairObjectValue(item.value, item.key))
    .value();
}

function getLowResUrl(url) {
  return url.replace('https://s3.us-west-1.wasabisys.com/butacasource/', '');
}

function generateBrandUrl(brand, url) {
  return brand ? `/${brand}${url}` : url;
}

function getTooltip(title, tooltip) {
  return (
    <span>
      {title} <Tooltip title={tooltip}><Icon type="info-circle" /></Tooltip>
    </span>
  )
}

/**
 * Swap items into an array
 *
 * @param array array to swap
 * @param indexA first element
 * @param indexB second element
 *
 * @returns swapped copy of the original array
 */
const swapItemsInArray = (array, indexA, indexB) => {
  const clonedArray = cloneDeep(array);
  let temp = clonedArray[indexA];
  clonedArray[indexA] = clonedArray[indexB];
  clonedArray[indexB] = temp;
  return clonedArray;
};

const getPlatformName = (platforms, value) => {
  if (isObject(value)) {
    return value.name;
  }

  const item = platforms.find(data => data._id === value);
  if (item) {
    return item.name;
  }

  return '';
};

const onSelectFilter = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
const adjustPrefixes = (base, prefixes, isRemoved) => {
  if (isRemoved) {
    const index = prefixes.findIndex(item => item.prefix === base);
    if (index > -1) {
      return prefixes.slice(0, index + 1);
    }
  } else {
    prefixes.push({
      name: base.split('/').filter(item => !!item).pop(),
      prefix: base
    });
    return prefixes;
  }
}

const mfaQRImageUrl = userId => {
  return `${API_URL}/mfa/qr_code/${userId}`;
}

const getChartStreamerSeries = (data, streamer, field) => {
  return {
    name: PLATFORM[streamer],
    data: data.filter(item => item.streamer === streamer).map(item => ({
      x: item.date,
      y: item[field]
    }))
  }
}

const getChartChannelSeries = (data, channel, field) => {
  return {
    name: channel,
    data: data.filter(element => element.channel === channel).map(item => ({
      x: item.date,
      y: item[field]
    }))
  };
}

const getChartSeries = (data, name, field) => {
  return {
    name,
    data: data.map(item => ({
      x: item.date,
      y: item[field]
    }))
  }
}

const getErrorMessage = error => ((error.response || {}).data || {}).message || error.message;

const getFilterOption = (input, option) => {
  return (option.props.children || '').toLowerCase().indexOf((input || '').toLowerCase()) >= 0;
}

const getSiteUrl = () => {
  return process.env.REACT_APP_SITE_URL;
}

const getApiUrl = () => {
  return process.env.REACT_APP_API_URL;
}

export {
  pairObject,
  pairObjectValue,
  unPairObject,
  labelInValue,
  mapId,
  getOneUploadId,
  getManyUploadId,
  getBase64,
  mapToFileList,
  slugify,
  isIframe,
  orderByAscending,
  isArrayOfString,
  getAbilitySubject,
  getAbilityAction,
  convertFieldToAction,
  convertActionToField,
  difference,
  toHyphenCase,
  transformFilters,
  transformRightsHolder,
  transformRightsHolderWithKey,
  getLowResUrl,
  generateBrandUrl,
  swapItemsInArray,
  mapFilter,
  getTooltip,
  getPlatformName,
  onSelectFilter,
  adjustPrefixes,
  convertToFileList,
  mfaQRImageUrl,
  getChartSeries,
  getChartStreamerSeries,
  getChartChannelSeries,
  getErrorMessage,
  getFilterOption,
  getSiteUrl,
  getApiUrl,
};
